import "core-js/modules/es.date.to-string.js";
import "core-js/modules/web.timers.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  key: 0,
  "class": "greeting fixed top-4 left-4 p-3 bg-green-600 text-white rounded-xl shadow-md z-50"
};
var _hoisted_2 = {
  "class": "text-sm font-medium"
};
import { ref, onMounted } from 'vue';
export default {
  __name: 'App',
  setup: function setup(__props) {
    var greetingMessage = ref('');
    var showMessage = ref(false);

    // 问候语函数
    // 使其每天某个时段打开网站只会首次进行提醒。例如，上午提醒了一次之后就不再提醒了，到中午才提醒。
    var getGreetingMessage = function getGreetingMessage() {
      var currentHour = new Date().getHours();
      var lastGreetedPeriod = localStorage.getItem('lastGreetedPeriod');
      var todayDate = new Date().toLocaleDateString();
      var currentPeriod;
      if (currentHour >= 5 && currentHour < 11) {
        currentPeriod = "".concat(todayDate, "-morning");
      } else if (currentHour >= 11 && currentHour < 13) {
        currentPeriod = "".concat(todayDate, "-noon");
      } else if (currentHour >= 13 && currentHour < 18) {
        currentPeriod = "".concat(todayDate, "-afternoon");
      } else if (currentHour >= 18 && currentHour < 22) {
        currentPeriod = "".concat(todayDate, "-evening");
      } else {
        currentPeriod = "".concat(todayDate, "-night");
      }
      if (lastGreetedPeriod === currentPeriod) {
        return '';
      }
      localStorage.setItem('lastGreetedPeriod', currentPeriod);
      if (currentHour >= 5 && currentHour < 11) {
        return "早上好！新的一天，满是希望！🌞";
      } else if (currentHour >= 11 && currentHour < 13) {
        return "中午好！小憩一下，继续前行！☕";
      } else if (currentHour >= 13 && currentHour < 18) {
        return "下午好，愿你一切顺利！🌿";
      } else if (currentHour >= 18 && currentHour < 22) {
        return "晚上好，放松心情，享受宁静时光！🌙";
      } else {
        return "深夜了，愿你安然入睡，梦中见到美好的明天！🌙💤";
      }
    };
    onMounted(function () {
      var message = getGreetingMessage();
      if (message) {
        greetingMessage.value = message;
        showMessage.value = true;

        // 自动隐藏提醒，3秒后消失
        setTimeout(function () {
          showMessage.value = false;
        }, 3000); // 3秒后隐藏
      }
    });

    return function (_ctx, _cache) {
      var _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_router_view), showMessage.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, _toDisplayString(greetingMessage.value), 1)])) : _createCommentVNode("", true)], 64);
    };
  }
};